import * as  React from 'react';
import { FacebookIcon } from '../../Icons/FacebookIcon';
import { MailIcon } from '../../Icons/MailIcon';
import { Logo } from '../../Logo';

export const CompanySummary: React.FC = () => (
  <section
    className="max-w-full flex md:justify-center sm:mx-auto px-3 md:px-4 pt-5 xl:px-8 text-left content-color main-page-footer-testimonials"
  >
    <div className="flex container flex-wrap-reverse mx-auto mb-3 md:mb-0 md:py-5 text-base md:text-sm">
      <div className="w-full lg:w-2/3 flex md:flex-row flex-col sm:ml-0 distance-to-left">
        <div className="lg:w-4/12 flex flex-col my-5">
          <h2 className="pb-3 pt-5 md:py-5 lg:pt-0 text-lg md:text-base font-bold ">
            OBSŁUGA KLIENTA
          </h2>
          <p className="pb-5 md:pb-3 text-base md:text-sm">
            Biuro Obsługi Klienta<br/>
            czynne od&nbsp;poniedziałku do&nbsp;piątku<br/>
            od 9:00 do 17:00
          </p>
          <div>
            <a
              href="/kontakt"
              className="inline-flex flex-row md:text-sm company-summary-item"
              style={{ lineHeight: '24px' }}
            >
              <MailIcon width='20' height='20'/>
              &nbsp;&nbsp;formularz kontaktowy
            </a>
          </div>
        </div>
        <div className="lg:w-5/12 flex flex-col md:flex-1 md:px-2 mb-3 md:py-5">
          <div className="flex flex-col md:self-center">
            <h2 className="pb-3 pt-5 md:py-5 lg:pt-0 text-lg md:text-base font-bold">
              MATERIAŁY
            </h2>
            <a href="/cennik">Cennik</a>
            <a className="flex flex-row my-3">
              Blog
              <span>&nbsp;- już wkrótce</span>
            </a>
            <div className="lg:mt-1">
              <a href="https://www.facebook.com/fakturuj.to/" target="_blank" className="company-summary-item">
                <FacebookIcon width="30" height="30" className="inline"/>
              </a>
            </div>
          </div>
        </div>
        <div className="lg:w-3/12 flex flex-col mb-3 md:py-5">
          <div className="flex flex-col md:self-center">
            <h2 className="pb-3 pt-5 md:py-5 lg:pt-0 text-lg md:text-base font-bold ">
              KONTO
            </h2>
            <a href="https://app.fakturuj.to/logowanie" className="mb-3 text-base md:text-sm">Logowanie</a>
            <a href="https://app.fakturuj.to/szybka-rejestracja">Rejestracja</a>
          </div>
        </div>
      </div>
      <div
        className="lg:w-1/3 flex flex-col items-center lg:items-end md:w-auto my-3 lg:mt-0 lg:pb-3 pt-5 md:text-sm text-center lg:text-right"
      >
        <a href="/#">
          <Logo
            style={{ width: '150' }}
            className="pb-5"
          />
        </a>
        <p className="pb-5 md:w-3/5 lg:w-full">
          Niezależnie czy chcesz wystawić fakturę sprzedaży czy fakturę zaliczkę, zawsze zrobisz to w sposób
          intuicyjny
          i&nbsp;ekspresowy!
        </p>
        <form
          action="https://app.fakturuj.to/szybka-rejestracja"
        >
          <button
            type="submit"
            className="content-color leading-loose py-3 pl-6 pr-6 hover:shadow-none company-summary-button font-bold uppercase rounded-full shadow-lg"
          >
            ZAKŁADAM DARMOWE KONTO
          </button>
        </form>
      </div>
    </div>
  </section>
);
