import React from 'react';
import { SuiteView } from '../../SuiteView/SuiteView';
import { PlansContainer } from './Pricing-styled';

export const getPriceDescription = (yearlyPrice, oldPrice) => (
  <>
    <span>{yearlyPrice}<sub>/rok</sub></span>
    <s>{oldPrice}</s>
  </>
);

const demoPlan = {
  'name': 'Demo',
  'price': '0zł',
  'priceDescription': (
    <>
      <span>NA ZAWSZE!</span>
    </>
  ),
  'discountDescription': '',
  'features': [
    {label: 'Faktury: do 5 / miesiąc'},
    {label: 'Zamówienia nielimitowane'},
    {label: 'Wysyłka dokumentów e-mailem'},
    {label: 'Klienci: do 10'},
    {label: '1 Użytkownik'},
    {label: 'Dostęp dla Księgowej / Księgowego'},
    {label: 'Raport z VIES / MF'},
    {label: ''},
    {label: ''},
    {label: ''},
    {label: ''},
  ],
};
const start = {
  'name': 'Start',
  'price': '9zł',
  'priceDescription': getPriceDescription('90zł', '108zł'),
  'discountDescription': 'Oszczędzasz 18zł',
  'features': [
    {label: 'Faktury: do 10 / miesiąc, do 120 / rok'},
    {label: 'Zamówienia nielimitowane'},
    {label: 'Wysyłka dokumentów e-mailem'},
    {label: 'Klienci nielimitowani'},
    {label: '1 Użytkownik'},
    {label: 'Dostęp dla Księgowej / Księgowego'},
    {label: 'Raport z VIES / MF'},
    {label: 'Faktury cykliczne (automat)'},
    {label: 'Raporty kasowe'},
    {label: ''},
    {label: ''},
  ],
};
const standard = {
  'name': 'Standard',
  'price': '19zł',
  'priceDescription': getPriceDescription('190zł', '228zł'),
  'discountDescription': 'Oszczędzasz 38zł',
  'features': [
    {label: 'Faktury nielimitowane'},
    {label: 'Zamówienia nielimitowane'},
    {label: 'Wysyłka dokumentów e-mailem'},
    {label: 'Klienci nielimitowani'},
    {label: '3 Użytkowników'},
    {label: 'Dostęp dla Księgowej / Księgowego'},
    {label: 'Raport z VIES / MF'},
    {label: 'Faktury cykliczne (automat)'},
    {label: 'Raporty kasowe'},
    {label: 'Logo Twojej firmy na fakturze'},
    {label: ''},
  ],
};
const pro = {
  'name': 'Pro',
  'price': '49zł',
  'priceDescription': getPriceDescription('540zł', '588zł'),
  'discountDescription': 'Oszczędzasz 48zł',
  'features': [
    {label: 'Faktury nielimitowane'},
    {label: 'Zamówienia nielimitowane'},
    {label: 'Wysyłka dokumentów e-mailem'},
    {label: 'Klienci nielimitowani'},
    {label: '5 Użytkowników'},
    {label: 'Dostęp dla Księgowej / Księgowego'},
    {label: 'Raport z VIES / MF'},
    {label: 'Faktury cykliczne (automat)'},
    {label: 'Raporty kasowe'},
    {label: 'Logo Twojej firmy na fakturze'},
    {label: 'Indywidualne doradztwo księgowe'},
  ],
};

export const Pricing = () => (
  <div className="flex flex-1 flex-col">
    <div className="container flex items-center justify-center">
      <p className="pb-10 text-center content-color">
        FAKTURUJ.TO sprawia, że wystawianie dokumentów i podstawowe czynności księgowe w Twojej firmie są szybsze i
        przyjemniejsze.
      </p>
    </div>

    <div
      style={{fontSize: '62.5%', lineHeight: 1.25}}
      className='text-left'
    >
      <PlansContainer>
        <SuiteView
          planData={demoPlan}
        />
        <SuiteView
          planData={start}
        />
        <SuiteView
          recommended
          planData={standard}
        />
        <SuiteView
          planData={pro}
        />
      </PlansContainer>
    </div>
  </div>
);

Pricing.defaultProps = {};

Pricing.propTypes = {};
